import React from 'react'
import { getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import Section from 'components/Section/Section'
import PageLayout from '../layouts/PageLayout'
import Seo from 'components/Seo'
import SectionImage from 'components/Section/SectionImage'

const NotFoundPage = ({ data: { file }, location: { pathname } }) => {
  const content = {
    header: '404: Page Not Found',
    subheader: "You just hit a route that doesn't exist… the despair.",
    posX: 'left',
    posY: 'bottom',
  }
  const ctaButton = {
    buttonText: 'Back to Home',
    buttonURL: '/',
  }
  const backgroundImage = getImage(file)

  return (
    <PageLayout pathname={pathname}>
      <Seo title='404: Not found | Iliad Epic Grow' meta={{}} noIndex />
      <Section
        index={0}
        fullHeight
        id={`page-section-0`}
        as='header'
        colorTheme='black'
        backgroundImage={backgroundImage}
        colorOverlay
        content={content}
        ctaButton={ctaButton}
        pathname={pathname}
        image={
          <SectionImage
            image={content.contentImage?.childImageSharp.gatsbyImageData}
            imagePosX={content.imagePosX}
            imagePosY={content.imagePosY}
          />
        }
      />
    </PageLayout>
  )
}

export default NotFoundPage

export const query = graphql`
  query NotFoundPageQuery {
    file(relativePath: { eq: "achiles-displaying-hector.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
